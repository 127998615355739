import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container pt-3 pb-5" }
const _hoisted_2 = { class: "card p-5" }
const _hoisted_3 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { colspan: "8" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { colspan: "8" }
const _hoisted_8 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

import { ref, onMounted, inject } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GraffitiIndex',
  setup(__props) {

const isLoading = ref(true);
const api: any = inject("api");
const devices = ref([]);
const graffitiDevices = ref([]);

const getDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    devices.value = data.data;
    graffitiDevices.value = data.data.filter((device) => device.type == 3);
    console.log(data.data);
    console.log(graffitiDevices.value);
    isLoading.value = false;
  });
};

onMounted(() => {
  getDevices();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
          _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase gs-0" }, [
            _createElementVNode("th", { class: "" }, "Name"),
            _createElementVNode("th", { class: "" }, "Location"),
            _createElementVNode("th", { class: "" }, "Status")
          ])
        ], -1)),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_4, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t("tips.loading")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (!isLoading.value && devices.value.length == 0)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.$t("tips.nodata")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (!isLoading.value && devices.value.length > 0)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((graffitiDevices.value as any), (device, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_router_link, {
                      to: {
                  name: 'graffitiDetail',
                  params: { id: device.device_id },
                }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(device.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("td", null, _toDisplayString(device.location), 1),
                  (device.status == '1')
                    ? (_openBlock(), _createElementBlock("td", _hoisted_9, _cache[0] || (_cache[0] = [
                        _createElementVNode("div", { class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" }, " Online ", -1)
                      ])))
                    : _createCommentVNode("", true),
                  (device.status == '2')
                    ? (_openBlock(), _createElementBlock("td", _hoisted_10, _cache[1] || (_cache[1] = [
                        _createElementVNode("div", { class: "btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3" }, " Offline ", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})