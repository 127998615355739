import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center me-5 mb-2 position-relative" }
const _hoisted_2 = { class: "me-3" }
const _hoisted_3 = { class: "text-primary fw-bold" }
const _hoisted_4 = { class: "dropdown-container position-relative" }
const _hoisted_5 = ["onClick"]

import { ref, onMounted, onUnmounted } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SensitivitySelect',
  props: {
  modelValue: {
    type: String,
    default: "Medium",
  },
  options: {
    type: Array,
    default: () => ["Low", "Medium", "High"],
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const showDropdown = ref(false);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const closeDropdown = (event: MouseEvent) => {
  if (!(event.target as Element).closest(".dropdown-container")) {
    showDropdown.value = false;
  }
};

const selectSensitivity = (option: string) => {
  if (confirm("Are you sure to change the sensitivity?")) {
    emit("update:modelValue", option);
  }
  showDropdown.value = false;
};

onMounted(() => {
  document.addEventListener("click", closeDropdown);
});

onUnmounted(() => {
  document.removeEventListener("click", closeDropdown);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createTextVNode(" Current sensitivity: ")),
      _createElementVNode("span", _hoisted_3, _toDisplayString(__props.modelValue), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "btn btn-sm btn-light-primary",
        onClick: _withModifiers(toggleDropdown, ["stop"])
      }, " Edit Sensitivity "),
      _createElementVNode("div", {
        class: _normalizeClass([
          'dropdown-menu',
          'position-absolute',
          'start-0',
          'mt-2',
          { show: showDropdown.value },
        ]),
        style: {"min-width":"120px","z-index":"1000"}
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
          return (_openBlock(), _createElementBlock("a", {
            key: option,
            class: "dropdown-item cursor-pointer",
            onClick: ($event: any) => (selectSensitivity(option))
          }, _toDisplayString(option), 9, _hoisted_5))
        }), 128))
      ], 2)
    ])
  ]))
}
}

})