const StreamsDate = [
  {
    "02a2c746-1eb1-4e0f-98db-a23856d01833": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV197.stream",
        },
      },
      name: "Bellevue Intersection5",
    },
    "087152d0-6b11-4389-a62c-12f13382649f": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV047S.stream",
        },
      },
      name: "Bellevue Intersection7 S",
    },
    "0dca3727-9e5b-48c3-b74a-c3f83adef925": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV223E.stream",
        },
      },
      name: "Bellevue Intersection3 E",
    },
    "190ca04e-bf95-4670-aa3d-cf538c688693": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV026N.stream",
        },
      },
      name: "Bellevue Intersection2 N",
    },
    "1fc1390c-7156-4859-8cd1-77fbc1bcfa41": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV048S.stream",
        },
      },
      name: "Bellevue Intersection6 S",
    },
    "3816da65-1e44-4aae-904a-029209b82017": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://admin:aiwaysion01@65.76.192.217/video1",
        },
      },
      name: "NYC Columbus \u0026 86th",
    },
    "39680873-1d3c-4e7d-a6ff-a523aadefb44": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.237.234:554/1/h264major",
        },
      },
      name: "Bellevue Roadway 2",
    },
    "3e90713a-62ab-46f2-a64e-871525c21b3f": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.224.252:554/1/h264major",
        },
      },
      name: "Tacoma Graffiti 1",
    },
    "5b7a9a4d-900e-460c-a9de-88ad1c35ed14": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV063N.stream",
        },
      },
      name: "Bellevue Intersection1 N",
    },
    "652ab8c4-236d-4630-b4a2-1e2aa13f5762": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV223S.stream",
        },
      },
      name: "Bellevue Intersection3 S",
    },
    "6b72370d-02d1-4e6b-9f61-96f6a83c1858": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://admin:aiwaysion01@65.76.209.142/video1",
        },
      },
      name: "NYC 74th b/w Amsterdam \u0026 Columbus",
    },
    "70cfe342-4d0e-4b71-95ba-ba3949623192": {
      channels: {
        "0": {
          debug: true,
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV063.stream",
        },
      },
      name: "Bellevue Intersection1",
    },
    "76687ddb-fbd9-414f-af4c-66e07b61d32e": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV063S.stream",
        },
      },
      name: "Bellevue Intersection1 S",
    },
    "79972129-4fc1-445c-ab3e-a8b4ce3d8229": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.97.33:554/1/h264major",
        },
      },
      name: "Spokane Graffiti 1",
    },
    "7b8ed752-8370-4189-8db5-298d88674807": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.244.159:554/1/h264major",
        },
      },
      name: "I-5 N Roadway 1",
    },
    "7ead599f-ed5a-43a2-8ab5-4b744bd48616": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV197E.stream",
        },
      },
      name: "Bellevue Intersection5 E",
    },
    "80ad2530-f2cb-4a04-a5a3-b4caed40107c": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV033.stream",
        },
      },
      name: "Bellevue Intersection4",
    },
    "8290e608-6bc5-45b9-8412-e015213aa964": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.125.203:554/1/h264major",
        },
      },
      name: "Bellevue Roadway 1",
    },
    "8b916404-52d7-4e9d-8285-950c9aee7741": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV026S.stream",
        },
      },
      name: "Bellevue Intersection2 S",
    },
    "8c22a16f-8655-42df-9aec-99fe67a1b9f7": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV033S.stream",
        },
      },
      name: "Bellevue Intersection4 S",
    },
    "8d351f39-6736-43dc-8464-08daafcefa92": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV033N.stream",
        },
      },
      name: "Bellevue Intersection4 N",
    },
    "8e5db572-b7c2-4083-897f-d255517815c3": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV048.stream",
        },
      },
      name: "Bellevue Intersection6",
    },
    "94b63be9-05b6-4629-b48d-53a9c6bc7432": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV048N.stream",
        },
      },
      name: "Bellevue Intersection6 N",
    },
    "9533774a-9d9e-45b0-be0a-194ff38c648d": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV197W.stream",
        },
      },
      name: "Bellevue Intersection5 W",
    },
    "98357988-3534-4ebf-88c2-bdd013dc83f2": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV026.stream",
        },
      },
      name: "Bellevue Intersection2",
    },
    "a8825239-198b-415c-ab1c-0a521dfebe26": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV047W.stream",
        },
      },
      name: "Bellevue Intersection7 W",
    },
    "ac1b72a9-f003-46bf-922e-683a30770f6c": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.133.114:554/1/h264major",
        },
      },
      name: "Bellevue Roadway 3",
    },
    "acf8e78a-2fd8-4d3a-ae86-e5480c176cd2": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.100.63:554/1/h264major",
        },
      },
      name: "Lynnwood Roadway 1",
    },
    "b2cd1b96-83c3-4cf6-afe1-535946a4508a": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV026E.stream",
        },
      },
      name: "Bellevue Intersection2 E ",
    },
    "bc44a8fd-21bd-4d52-9993-9d23b198ab60": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.127.204:554/1/h264major",
        },
      },
      name: "Tucson Intersection 1 NW",
    },
    "bdac8d91-6436-4983-8552-6347441ed6c8": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV048E.stream",
        },
      },
      name: "Bellevue Intersection6 E",
    },
    "bf455361-70fc-45a7-8c4c-a277d8c4b479": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV063E.stream",
        },
      },
      name: "Bellevue Intersection1 E",
    },
    "d6d727f5-535b-409f-b8ab-3c826387b99e": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV047.stream",
        },
      },
      name: "Bellevue Intersection7",
    },
    "da6d1dd8-3666-4113-96d4-6798ebac839c": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.198.185:554/1/h264major",
        },
      },
      name: "Tucson Intersection 1 SE",
    },
    "db061780-d08e-45e2-9670-e119e3720e05": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://65.76.54.158:554/1/h264major",
        },
      },
      name: "Yakama1",
    },
    "dc8da8e4-d479-4552-9f89-1098064cd7e2": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://admin:aiwaysion01@65.76.177.67/video1",
        },
      },
      name: "NYC Amsterdam \u0026 80th",
    },
    "dd01bdf4-b234-46e0-8142-009e004c4ffd": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV223W.stream",
        },
      },
      name: "Bellevue Intersection3 W",
    },
    "df1ccd62-1684-4d35-ae95-8111330124b2": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV223.stream",
        },
      },
      name: "Bellevue Intersection3",
    },
    "e3b22819-a9c9-48b7-aaec-bba0d94a7c6e": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV197S.stream",
        },
      },
      name: "Bellevue Intersection5 S",
    },
    "e65cb1b7-a8c4-4b7b-95d3-14f40042315e": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV033W.stream",
        },
      },
      name: "Bellevue Intersection4 W",
    },
    "fd2d7e3e-827f-45ad-8820-1be77a8ecd79": {
      channels: {
        "0": {
          on_demand: true,
          url: "rtsp://wowza01.bellevuewa.gov:1935/live/CCTV047NE.stream",
        },
      },
      name: "Bellevue Intersection7 N",
    },
  },
];

export default StreamsDate;
