import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body pt-2" }
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "text-muted fw-semobold d-block" }
const _hoisted_9 = { class: "col-9" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = { class: "card mb-5 mb-xl-10" }
const _hoisted_13 = { class: "card-body pt-9 pb-0" }
const _hoisted_14 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_15 = { class: "flex-grow-1" }
const _hoisted_16 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_17 = { class: "d-flex flex-column" }
const _hoisted_18 = { class: "d-flex align-items-center mb-2" }
const _hoisted_19 = { class: "text-gray-800 fs-2 fw-bold me-1" }
const _hoisted_20 = {
  key: 0,
  class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
}
const _hoisted_21 = {
  key: 1,
  class: "btn btn-sm btn-light-danger fw-bold ms-2 fs-8 py-1 px-3"
}
const _hoisted_22 = { class: "d-flex fw-semobold fs-6 mb-2 pe-2 vstack" }
const _hoisted_23 = { class: "d-flex align-items-center me-5 mb-2" }
const _hoisted_24 = { class: "d-flex align-items-center me-5 mb-2" }
const _hoisted_25 = { class: "card-header card-header-stretch" }
const _hoisted_26 = {
  class: "nav nav-stretch nav-line-tabs fw-semobold border-0",
  role: "tablist",
  id: "kt_layout_builder_tabs",
  ref: "kt_layout_builder_tabs"
}
const _hoisted_27 = { class: "nav-item" }
const _hoisted_28 = { class: "nav-item" }
const _hoisted_29 = { class: "card-body" }
const _hoisted_30 = {
  class: "tab-content pt-3",
  id: "kt_tabs"
}
const _hoisted_31 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3 m-5" }
const _hoisted_32 = { class: "col-12 mt-5" }
const _hoisted_33 = {
  key: 0,
  src: "/images/no_image_available.jpeg",
  class: "img-fluid",
  alt: "no_image_available"
}
const _hoisted_34 = ["src"]
const _hoisted_35 = {
  id: "mse-video",
  autoplay: "",
  muted: "",
  playsinline: "",
  controls: "",
  style: {"max-width":"100%","max-height":"100%"}
}

import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import DeviceMap from "@/views/devices/DeviceMap.vue";
import StreamsDate from "@/views/intersection/components/StreamsData";


export default /*@__PURE__*/_defineComponent({
  __name: 'ParkingDetail',
  setup(__props) {

const isLoading = ref(true);
const route = useRoute();
const deviceList = ref([]);
const device_id = ref(route.params.id);
const device = ref();
const api = inject("api");
const tabIndex = ref(0);
const imageUrl = ref("");

const switchDevice = async (show_device_id) => {
  if (showLiveStream.value) {
    toggleCheckbox();
  }
  device_id.value = show_device_id;
  getDeviceDetail();
  setActiveTab({ target: { getAttribute: () => 0 } });
};

const getDeviceDetail = async () => {
  api["devices.getDeviceDetail"]({ device_id: device_id.value }).then(
    (data) => {
      device.value = data.data;
      getDeviceCurrentImageFromCamera(device.value.ip);
      findDeviceStreamUUID(device.value.name);
      isLoading.value = false;
    }
  );
};

const getDeviceList = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    deviceList.value = data.data.filter((device) => device.type == 4);
  });
};

const setActiveTab = (event) => {
  tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
};

const getDeviceCurrentImageFromCamera = async (device_ip) => {
  try {
    // Set the imageUrl to the GCP proxy server endpoint directly
    imageUrl.value = `https://aiwaysionapi.com/camera-image?device_ip=${device_ip}`;
  } catch (error) {
    console.error("Error setting camera image URL:", error);
  }
};

onMounted(async () => {
  await getDeviceList();
  await getDeviceDetail();
  tabIndex.value = 0;
});

// when the component is unmounted, check if the live stream is on, if so, stop it
onUnmounted(() => {
  if (showLiveStream.value) {
    stopLiveStream();
  }
});

// Code for show live stream !!!!!!!!!!!!!!!!!!!!!!!
const showLiveStream = ref(false);
const streamsData = StreamsDate[0];
const dynamicStreamUrl = ref("");
const mseQueue = [];
let mseSourceBuffer: SourceBuffer;
let mseStreamingStarted = false;
let mse: MediaSource | null = null;
let ws: WebSocket | null = null;

// Toggle live stream
const toggleCheckbox = () => {
  showLiveStream.value = !showLiveStream.value;
  //the the toggle to check or uncheck
  const toggle = document.getElementById(
    "LiveStreamToggle"
  ) as HTMLInputElement;
  toggle.checked = showLiveStream.value;

  if (showLiveStream.value) {
    startPlay(document.querySelector("#mse-video"), dynamicStreamUrl.value);
  } else {
    stopLiveStream();
  }
};

const findDeviceStreamUUID = (deviceName: string) => {
  for (const stream in streamsData) {
    if (streamsData[stream].name === deviceName) {
      dynamicStreamUrl.value = `wss://v.aiwaysion.com/stream/${stream}/channel/0/mse`;
      return;
    }
  }
};

// Stop the live stream
function stopLiveStream() {
  // Immediately stop processing any more incoming messages
  if (ws) {
    ws.onmessage = null; // Remove the message handler
    ws.close();
    ws = null;
  }

  // Check if mse and sourceBuffer exist and are in a state that allows ending the stream
  if (mse && !mseSourceBuffer.updating && mse.readyState === "open") {
    try {
      mse.endOfStream(); // Signal end of stream
    } catch (error) {
      console.error("Error ending media stream:", error);
    }
  }

  mse = null;
  mseSourceBuffer = null; // Clear the source buffer reference

  // Reset the video element
  const videoEl = document.querySelector("#mse-video") as HTMLVideoElement;
  if (videoEl) {
    videoEl.pause();
    videoEl.src = "";
    videoEl.load(); // Load with empty source to reset
  }

  // Clear any queued data
  mseQueue.length = 0;
  // getDeviceCurrentImage();
  console.log("stoped live stream");
}

function startPlay(videoEl: Element | null, url: string | URL) {
  // Close any existing WebSocket connection
  mse = new MediaSource();
  if (videoEl) {
    const videoEl = document.querySelector("#mse-video") as HTMLVideoElement;
    videoEl.src = window.URL.createObjectURL(mse);
  }
  mse.addEventListener(
    "sourceopen",
    function () {
      ws = new WebSocket(url);
      ws.binaryType = "arraybuffer";
      ws.onmessage = function (event) {
        const data = new Uint8Array(event.data);
        if (data[0] === 9) {
          let mimeCodec: string;
          const decodedArr = data.slice(1);
          if (window.TextDecoder) {
            mimeCodec = new TextDecoder("utf-8").decode(decodedArr);
          } else {
            mimeCodec = Utf8ArrayToStr(decodedArr);
          }
          mseSourceBuffer = mse.addSourceBuffer(
            'video/mp4; codecs="' + mimeCodec + '"'
          );
          mseSourceBuffer.mode = "segments";
          mseSourceBuffer.addEventListener("updateend", pushPacket);
        } else {
          readPacket(event.data);
        }
      };
    },
    false
  );
}

function pushPacket() {
  const videoEl = document.querySelector("#mse-video");
  let packet: undefined;

  if (
    mse &&
    mseSourceBuffer &&
    !mseSourceBuffer.updating &&
    mseQueue.length > 0
  ) {
    const packet = mseQueue.shift();
    try {
      mseSourceBuffer.appendBuffer(packet);
    } catch (error) {
      console.error("Error appending buffer:", error);
      // Handle the error, potentially by stopping the stream or alerting the user
    }
  }

  if (!mseSourceBuffer.updating) {
    if (mseQueue.length > 0) {
      packet = mseQueue.shift();
      mseSourceBuffer.appendBuffer(packet);
    } else {
      mseStreamingStarted = false;
    }
  }
  if (videoEl.buffered.length > 0) {
    if (typeof document.hidden !== "undefined" && document.hidden) {
      // no sound, browser paused video without sound in background
      videoEl.currentTime =
        videoEl.buffered.end(videoEl.buffered.length - 1) - 0.5;
    }
  }
}

function readPacket(packet: any) {
  if (!mseStreamingStarted) {
    mseSourceBuffer.appendBuffer(packet);
    mseStreamingStarted = true;
    return;
  }
  mseQueue.push(packet);
  if (!mseSourceBuffer.updating) {
    pushPacket();
  }
}

function Utf8ArrayToStr(array: string | any[] | Uint8Array) {
  var out: string, i: number, len: number, c: number;
  var char2: number, char3: number;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 7:
        out += String.fromCharCode(c);
        break;
      case 13:
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}
// just for demo

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("h1", null, "Loading...", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header border-0" }, [
                  _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Devices List")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deviceList.value, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "d-flex align-items-center mb-5"
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["bullet bullet-vertical h-40px", {
                      'bg-success': item.status == 1,
                      'bg-danger': item.status == 0,
                      'bg-second': item.status == 2,
                    }])
                      }, null, 2),
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_router_link, {
                          to: '/parking/' + item.device_id,
                          class: "text-gray-800 text-hover-primary fw-bold fs-6",
                          onClick: ($event: any) => (switchDevice(item.device_id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to", "onClick"]),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(item.location), 1)
                      ]),
                      _createElementVNode("span", {
                        class: _normalizeClass(["badge fs-8 fw-bold", `badge-light-`])
                      }, "New")
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "me-7" }, [
                          _createElementVNode("div", { class: "symbol symbol-100px symbol-lg-200px symbol-fixed position-cneter" }, [
                            _createElementVNode("img", {
                              class: "",
                              src: "/images/bg/MUST_Device.PNG",
                              alt: "Device image"
                            })
                          ])
                        ], -1)),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", _hoisted_19, _toDisplayString(device.value.name), 1),
                                (device.value.status == '1')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, " Online "))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_21, " Offline "))
                              ]),
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, " Location: " + _toDisplayString(device.value.location), 1),
                                _createElementVNode("div", _hoisted_24, " Installed at: " + _toDisplayString(device.value.created_at.split("T")[0]), 1)
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("ul", _hoisted_26, [
                          _createElementVNode("li", _hoisted_27, [
                            _createElementVNode("a", {
                              class: _normalizeClass(["nav-link", { active: tabIndex.value === 0 }]),
                              "data-bs-toggle": "tab",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (setActiveTab($event))),
                              "data-tab-index": "0",
                              href: "#kt_builder_overview",
                              role: "tab"
                            }, " Overview ", 2)
                          ]),
                          _createElementVNode("li", _hoisted_28, [
                            _createElementVNode("a", {
                              class: _normalizeClass(["nav-link", { active: tabIndex.value === 1 }]),
                              "data-bs-toggle": "tab",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (setActiveTab($event))),
                              "data-tab-index": "1",
                              href: "#kt_builder_map",
                              role: "tab"
                            }, " Map ", 2)
                          ])
                        ], 512)
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["tab-pane", { active: tabIndex.value === 0 }]),
                            id: "kt_builder_overview"
                          }, [
                            _createElementVNode("div", _hoisted_31, [
                              _createElementVNode("div", _hoisted_32, [
                                (device.value.status != 1)
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_33))
                                  : _createCommentVNode("", true),
                                (imageUrl.value && !showLiveStream.value)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 1,
                                      src: imageUrl.value,
                                      style: {"width":"100%"}
                                    }, null, 8, _hoisted_34))
                                  : _createCommentVNode("", true),
                                _withDirectives(_createElementVNode("video", _hoisted_35, null, 512), [
                                  [_vShow, showLiveStream.value]
                                ]),
                                _createElementVNode("div", { class: "form-check form-switch d-flex justify-content-end mb-3" }, [
                                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                                    class: "form-check-label fw-bold fs-3 pt-1 text-gray-400",
                                    for: "LiveStreamToggle"
                                  }, " Live Stream: ", -1)),
                                  _createElementVNode("input", {
                                    class: "form-check-input ms-2 mt-2",
                                    type: "checkbox",
                                    role: "switch",
                                    id: "LiveStreamToggle",
                                    unchecked: "",
                                    onClick: toggleCheckbox
                                  })
                                ])
                              ])
                            ])
                          ], 2),
                          (tabIndex.value === 1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["col-12 mt-5 mt-md-0", { active: tabIndex.value === 1 }]),
                                id: "kt_builder_map"
                              }, [
                                _createVNode(DeviceMap, {
                                  data: [device.value]
                                }, null, 8, ["data"])
                              ], 2))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
  ]))
}
}

})