<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <h3 class="card-title fw-bold">Historical Vehicle Detection Results</h3>
      </div>
      <div class="card-toolbar">
        <div class="d-flex align-items-center position-relative">
          <div class="input-group">
            <input
              v-model="startDate"
              type="date"
              class="form-control form-control-solid"
              placeholder="Start Date"
            />
            <span class="input-group-text">to</span>
            <input
              v-model="endDate"
              type="date"
              class="form-control form-control-solid"
              placeholder="End Date"
            />
            <button @click="fetchData" class="btn btn-primary" type="button">
              <i class="fas fa-search"></i>
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-5">
      <div v-if="loading" class="d-flex justify-content-center my-10">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else-if="error" class="alert alert-danger">
        {{ error }}
      </div>
      <div v-else-if="!detectionData.length" class="alert alert-info">
        No detection data available for the selected time period.
      </div>
      <div v-else class="table-responsive">
        <table
          class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
        >
          <thead>
            <tr class="fw-bold text-muted">
              <th>Date/Time</th>
              <th>Vehicle Class</th>
              <th>Direction</th>
              <th>Lane</th>
              <th>Speed</th>
              <th>Axles</th>
              <th>State</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in detectionData" :key="item.id">
              <td>{{ formatDateTime(item) }}</td>
              <td>{{ item.CLS }}</td>
              <td>{{ item.DIR }}</td>
              <td>{{ item.LN }}</td>
              <td>{{ item.SPD }} mph</td>
              <td>{{ item.NAX }}</td>
              <td>{{ getStateName(item.SFIPS) }}</td>
              <td>{{ item.id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between align-items-center pt-5">
        <!-- Rows per page selector (left-aligned) -->
        <div class="d-flex align-items-center">
          <span class="text-muted me-2">Rows per page:</span>
          <select
            v-model.number="pageSize"
            class="form-select form-select-sm form-select-solid"
            @change="handlePageSizeChange"
          >
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
          </select>
        </div>

        <!-- Pagination controls (right-aligned) -->
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center me-5">
            <span class="text-muted"
              >Showing
              {{ Math.min((currentPage - 1) * pageSize + 1, totalItems) }} to
              {{ Math.min(currentPage * pageSize, totalItems) }} of
              {{ totalItems }}</span
            >
          </div>
          <button
            @click="currentPage > 1 && changePage(currentPage - 1)"
            class="btn btn-sm btn-light-primary me-2"
            :disabled="currentPage <= 1"
          >
            <i class="fas fa-chevron-left"></i> Previous
          </button>
          <button
            @click="changePage(currentPage + 1)"
            class="btn btn-sm btn-light-primary"
            :disabled="currentPage * pageSize >= totalItems"
          >
            Next <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

const route = useRoute();
const deviceId = computed(() => route.params.id || "");

// Data
const loading = ref(false);
const error = ref(null);
const detectionData = ref([]);
const startDate = ref(
  new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0]
);
const endDate = ref(new Date().toISOString().split("T")[0]);
const currentPage = ref(1);
const pageSize = ref(10);
const totalItems = ref(0);

// State FIPS codes mapping (simplified)
const stateFipsMap = {
  53: "Washington",
  // Add more states as needed
};

// Fetch data from API
const fetchData = async () => {
  loading.value = true;
  error.value = null;

  try {
    // Construct the API URL
    const baseUrl = `http://34.83.39.108:7001/devices/${deviceId.value}/results`;

    // Add query parameters
    const params = new URLSearchParams();
    if (startDate.value) params.append("start_date", startDate.value);
    if (endDate.value) params.append("end_date", endDate.value);
    params.append("currentPage", currentPage.value.toString());
    params.append("pageSize", pageSize.value.toString());

    const url = `${baseUrl}?${params.toString()}`;

    const response = await axios.get(url);

    if (response.data && response.data.data) {
      detectionData.value = response.data.data;
      totalItems.value = response.data.total || response.data.data.length;
    } else {
      detectionData.value = [];
      totalItems.value = 0;
    }
  } catch (err) {
    console.error("Error fetching vehicle detection data:", err);
    error.value = "Failed to load detection data. Please try again later.";
    detectionData.value = [];
  } finally {
    loading.value = false;
  }
};

// Format date and time for display
const formatDateTime = (item) => {
  try {
    const year = item.YR;
    const month = item.MOY - 1; // JavaScript months are 0-indexed
    const day = item.DOM;
    const hour = item.HOD;
    const minute = item.MOH;
    const second = item.SOM;

    const date = new Date(year, month, day, hour, minute, second);
    return date.toLocaleString();
  } catch (err) {
    return "Invalid date";
  }
};

// Get state name from FIPS code
const getStateName = (fipsCode) => {
  return stateFipsMap[fipsCode] || `State ${fipsCode}`;
};

// Change page
const changePage = (page) => {
  currentPage.value = page;
  fetchData();
};

// Handle page size change
const handlePageSizeChange = () => {
  currentPage.value = 1; // Reset to first page when changing page size
  fetchData();
};

// Watch for changes to device ID
watch(
  deviceId,
  () => {
    currentPage.value = 1;
    fetchData();
  },
  { immediate: false }
);

// Watch for date changes
watch(
  [startDate, endDate],
  () => {
    currentPage.value = 1;
    fetchData();
  },
  { immediate: false }
);

// Fetch data on component mount
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
</style>
